<template>
    <section class="team-introduction">
        <img src="../assets/img/team.jpeg" alt="équipe d'artisanat van">
        <div>
            <h1>découvrez l'équipe qui sera <br><span>à vos côtés</span> lors de votre projet</h1>
            <button ref="scrollTopButton" @click="scrollToTop">
                <font-awesome-icon id="arrow-to-team" icon="fa-solid fa-arrow-down" />
            </button>
        </div>
    </section>
    <section ref="team" id="team" class="bgc-brick-red">
        <div class="teammates" >
            <div class="teammate" id="samuel-bloc">
                <img class="desktop-img" src="../assets/img/teammate-1.jpeg" alt="samuel">
                <span class="teammate-name">SAMUEL</span>
                <button class="teammate-button" v-on:click="showMateInfos('samuel')">
                    <img id="samuel-img" class="mobile-img" src="../assets/img/teammate-1.jpeg" alt="samuel">
                    <div id="samuel-text">
                        <span>SAMUEL</span>
                        <span id="samuel-more">Voir plus d'informations</span>
                    </div>                    
                </button>
                <div class="teammate-infos" id="samuel">
                    <div class="teammate-infos-title">
                        <h2>SAMUEL</h2>
                        <span>25 ans - originaire du Jura</span>
                    </div>
                    <p>Diplômé en cuisine pâtisserie, j’ai rapidement pratiqué les saisons. Les camions aménagés me passionnaient déjà depuis quelques années, 
                    j’ai donc conçu le miens pour y vivre durant ces dernières. J’ai pris un grand plaisir à le faire et le concevoir à mon image, étant un fervent pratiquant de la récup’ depuis tout petit, avec une éducation écologique. J’ai retranscrit ces valeurs dans mon petit chez moi. J’ai donc dû m’intéresser également à son ensemble qui comporte électricité, isolation... Étant autodidacte j’ai appris et comparé les méthodes de fabrication de celle-ci. J’ai profité également de celui-ci pour m’évader dans mes voyages tout en étant autonome en termes d’eau et d’électricité pendant plusieurs semaines. Après cet aménagement j’ai eu beaucoup de demande dû à la praticité, à l’aspect écologique et au côté chaleureux du bois que j’aime particulièrement travailler ! j’ai donc conçu plusieurs camions pour des amis durant ces 5 dernières années, prenant toujours un grand plaisir à réfléchir à l’architecture, aux matière premières que j’utilisais.</p>
                    <p>Étant affouagiste j’apprécie tout particulièrement prendre soin de mes forêts, et proposer à ma clientèle du bois que j’ai moi-même travaillé en partant d’un arbre brut (il n’y a pas plus local comme bois). Mon papa étant ébéniste de métier m’a appris à travailler le bois et ces valeurs, à utiliser les machines associées. À la suite je me suis donc mis à la réalisation de ceux-ci plus profondément, pour petit à petit arriver où j’en suis aujourd’hui.</p>
                </div>
            </div>
            <div class="teammate" id="loic-bloc">
                <img class="desktop-img" src="../assets/img/teammate-2.jpeg" alt="loic">
                <span class="teammate-name">LOIC</span>
                <button class="teammate-button" v-on:click="showMateInfos('loic')">
                    <img id="loic-img" class="mobile-img" src="../assets/img/teammate-2.jpeg" alt="loic">
                    <div id="loic-text">
                        <span>LOIC</span>
                        <span id="loic-more">Voir plus d'informations</span>
                    </div>                    
                </button>
                <div class="teammate-infos" id="loic">
                    <div class="teammate-infos-title">
                        <h2>LOIC</h2>
                        <span>26 ans - originaire du Jura</span>
                    </div>
                    <p>Travaillant le bois depuis tout petit avec mon grand père, mon orientation était déjà toute faite. En effet je suis tombé amoureux de cette matière, que ce soit dans la manière de l'usiner où de son rendu final. </p>
                    <p>Étant très méticuleux dans ce que j'entreprends j'ai donc, par la suite, suivi la formation pour devenir menuisier/ébéniste. À la fin de mes études j'ai travaillé pendant 6 ans dans la fabrication, la pose et l'aménagement de piscine extérieure, le tout, toujours en bois. Ce métier ne correspondait plus à mes valeurs. Je souhaitais donc me réorienter dans quelque chose plus en harmonie avec l'écologie.</p>
                    <p>J'ai donc décidé de rejoindre mon ami d'enfance qui venait de lancer son projet. J'ai également envie de faire vivre à autrui tout ce qu'offre la vie en van, soit la liberté, l'indépendance, la rencontre et le bonheur.</p>
                </div>
            </div>     
        </div>
    </section>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    data() {
        return {
            SamuelInfos : false,
            LoicInfos : false,
            oldName : ""
        }
    },
    methods: {
        scrollToTop() {
            const team = this.$refs.team;
            team.scrollIntoView({ top: 0, behavior: "smooth" });
        },
        showMateInfos(name) {
            const desc = document.getElementById(name);
            const container = document.getElementById(`${name}-bloc`);
            const img = document.getElementById(`${name}-img`)
            const text = document.getElementById(`${name}-text`)
            const lastText = document.getElementById(`${name}-more`)

            const oldDesc = document.getElementById(this.oldName);
            const oldContainer = document.getElementById(`${this.oldName}-bloc`);
            const oldImg = document.getElementById(`${this.oldName}-img`)
            const oldText = document.getElementById(`${this.oldName}-text`)
            const oldLastText = document.getElementById(`${this.oldName}-more`)


            if(this.oldName!==name && this.oldName!=="") {
                oldImg.classList.remove("smaller-img")
                oldText.classList.remove("text-img")
                oldLastText.classList.remove("erase")
                oldContainer.classList.remove("visible-container")
                oldDesc.classList.remove("visible")
                if ( this.oldName==='samuel' && this.SamuelInfos) {
                        this.SamuelInfos = !this.SamuelInfos
                }
                if ( this.oldName==='loic' && this.LoicInfos) {
                        this.LoicInfos = !this.LoicInfos
                }              
            }

            switch(name) {
                case 'samuel' :
                    if(this.SamuelInfos) {
                        img.classList.remove("smaller-img")
                        text.classList.remove("text-img")
                        lastText.classList.remove("erase")
                        container.classList.remove("visible-container")
                        desc.classList.remove("visible")
                    }else{
                        text.classList.add("text-img")
                        img.classList.add("smaller-img")
                        lastText.classList.add("erase")
                        container.classList.add("visible-container")
                        desc.classList.add("visible")
                    }
                    this.SamuelInfos = !this.SamuelInfos
                    break
                case 'loic' :
                    if(this.LoicInfos) {
                        img.classList.remove("smaller-img")
                        text.classList.remove("text-img")
                        lastText.classList.remove("erase")
                        container.classList.remove("visible-container")
                        desc.classList.remove("visible")
                    }else{
                        text.classList.add("text-img")
                        img.classList.add("smaller-img")
                        lastText.classList.add("erase")
                        container.classList.add("visible-container")
                        desc.classList.add("visible")
                    }
                    this.LoicInfos = !this.LoicInfos
                    break
                default:
                    break
            }
            this.oldName = name ;
        }
    },
});
</script>

<style scoped>
.team-introduction {
    padding: 0;
    display: flex;
    height: 950px;
}
.team-introduction img{
    width: 60%;
    object-fit: cover;
}
.team-introduction div {
    width: 44%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
h1 {
    font-size: 32px;
    line-height: 44px;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;
}
#arrow-to-team{
    color: black;
    font-size: 17px;
    padding: 10px 13px;
    border: 2px solid black;
    border-radius: 50%;
}
section {
    padding: 15% 10% 10%;
}
h1 span {
    text-transform: lowercase;
    font-family: Lora;
    font-style: italic;
    font-weight: lighter;
    font-size: 38px;
}
.teammates {
    display: flex;
    justify-content: space-between;
}
.teammate {
    flex-basis: 49.5%;
    height: 750px;
    position: relative;
}
.desktop-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.teammate-name {
    position: absolute;
    top: 48%;
    left: 42%;
    font-size: 28px;
    font-weight: 500;
    transition: all 500ms;
}
.teammate-button {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: white;
    font-size: 13px;
    width: 100%;
    padding: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    border-radius: 0;   
    border: 0; 
}
.teammate-button span:first-child {
    font-size: 16px;
    font-weight: 500;
}
.teammate-infos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    top: 0%;
    opacity: 0;
    background-color: rgba(58, 23, 23, 0.85);
    padding: 40px 80px 0;
    height: 710px;
    transition: all 500ms;
}
.teammate-infos-title {
    display: flex;
    align-items: flex-end;
    gap: 15px;
    border-bottom: 1px white solid;
    padding-bottom: 15px;
    margin-bottom: 25px;
}
.teammate-infos-title h2 {
    margin: 0;
    line-height: 25px;
    font-size: 28px;
    font-weight: 500;
}
.teammate-infos-title span, .teammate-infos p {
    font-weight: 300;
    font-size: 100%;
    line-height: 150%;
}
.teammate:hover .teammate-name{
    opacity: 0;
    transition: all 500ms ease;
}
.teammate:hover .teammate-infos {
    opacity: 1;
    transition: all 500ms ease;
}
@media (max-width: 1700px) {
    .teammate-infos-title span, .teammate-infos p {
        font-size: 90%;
    }
}
@media (max-width: 1450px) {
    .teammate-infos-title span, .teammate-infos p {
        font-size: 80%;
    }
}
@media (max-width: 1250px) {
    .teammate-infos-title span, .teammate-infos p {
        font-size: 75%;
        line-height: 125%;
    }
}
@media (max-width: 1050px) {
    .team-introduction {
        height: 925px;
        flex-direction: column;
    }
    .team-introduction img {
        width: 100%;
        height: 550px;
    }
    .team-introduction div {
        width: 100%;
        height: 370px;
    }
    h1 {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 10px;
    }
    h1 span {
        font-size: 28px;
    }
    #arrow-to-team {
        font-size: 12px;
        padding: 5px 7px;
        border: 1px solid black;
    }
    section {
        padding: 0;
        height: fit-content;
    }
    .teammates {
        flex-direction: column;
    }
    .teammate {
        flex-basis: auto;
        height: 225px;
        display: block;
        overflow: hidden;
        transition: height 500ms 250ms ease-out;
    }
    .desktop-img {
        display: none;
    }
    .mobile-img{
        object-fit: cover;
        width: 100%;        
        height: 225px;
        display: flex;
        transition: height 500ms ease-in-out;
    }
    .teammate-name {
        display: none;
    }
    .teammate-button {
        display: flex;
        z-index: 1;
    }
    .teammate-button div {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: all 500ms ease-in-out;
    }
    .teammate-button span {
        transition: all 500ms ease-in-out;
    }
    .teammate-infos {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        height: 100%;
        width: 280px;
        box-sizing: border-box;
        opacity: 1;
        margin: 0 auto;
        padding: 135px 0 50px;
        transform: translateY(700px);
        transition: transform 500ms ease-out;
        overflow: hidden;
    }
    .teammate-infos-title h2 {
        display: none;
    }
    .teammate-infos-title span {
        font-weight: 500;
    }
    .teammate:hover .teammate-name, .teammate:hover .teammate-infos {
        opacity: 1;
    }
    .visible {
        transform: translateY(0);
        transition: all 500ms 250ms ease-in;
    }
    .visible-container {
        height: 900px;
        transition: all 500ms ease-in;
    }
    .smaller-img {
        height: 135px;
        transition: all 500ms ease-in-out;
    }
    .text-img {
        background-color: rgba(58, 23, 23, 0.85);
        transition: all 500ms ease;
    }
    .erase {
        opacity: 0;
        transition: all 500ms ease-in-out;
    }
}

</style>
