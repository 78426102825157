<template>
    <section id="layout-beginning">
        <h1>découvrez nos différents <span>aménagements</span></h1>
        <button ref="scrollTopButton" @click="scrollToTop" id="arrow-to-layouts">
            <font-awesome-icon icon="fa-solid fa-arrow-down" />
        </button>
    </section>
    <section ref="layouts" id="layouts" class="bgc-brick-red">
        <p>Nos aménagements sont confectionnés principalement en bois récupéré pour donner un de chalet de montagne, autrement dit, chaleureux et confortable.</p>
    </section>
    <section class="materials">
        <div class="material ">
            <div class="material-image">
                <img src="../assets/img/layouts-2.jpeg" alt="taille du bois">
                <span>le bois</span>
            </div>            
            <div class="material-description">
                <p>Le gros de l'aménagement provient<br> principalement de matières récupérées tel que<br> du bois de palettes, des meubles destinés à être<br> jetés ou encore de petites chutes d'entreprises.</p>
                <p>Pour ce qui concerne l’aménagement, 40 à 60% de celui-ci est réalisé à partir de <strong>matières recyclées</strong>, du bois de palettes (sapin/épicéa/hêtre...) récupérés dans des organismes qui réceptionnent des matériaux où les palettes sont destinées à être jetés. Nous avons convenu d’un accord avec eux afin de leurs éviter ce triste sort et que l’on puisse leurs donner une <strong>seconde vie</strong>.</p>
                <p>Lorsque nous avons besoin de sections plus importante (où plus esthétique) comme lors de la réalisation d’un plateau de table ou d’un plan de travail, nous nous tournons soit vers des plateaux qui ont été débités et séchés lors de notre affouage ou acheté à des bûcherons directement. Pour ce qui n’est pas récupéré, nous faisons attention à la provenance du matériau choisi chez notre <strong>fournisseur</strong>. Ce dernier à également été choisi pour sa proximité et sa qualité de travail. Nous préconisons au maximum les produits de nos régions (bois du Jura ou régions voisines).</p>
            </div>
        </div>
        <div class="material">
            <div class="material-image">
                <img src="../assets/img/layouts-3.jpeg" alt="connexion électrique dans le van">
                <span>éléctricité <br>et options</span>
            </div>
            <div class="material-description">
                <p>Concernant la récupération de la plupart de nos matières première (environ 40 à 60% de notre aménagement) sont trouvés dans les entreprises, dans les vides maison, dans les brocantes, dans les déchetteries ou dans les structures de seconde main</p>
                <p>Nous récupérons le plus de matériel possible dans des structures de <strong>seconde main</strong> (oasis...) que ce soit par exemple nos interrupteurs, nos luminaires... Pour certains matériaux exigeants une bonne fiabilité tel que les panneaux solaire, batteries (stockage d'énergie), pompe à eau, système de chauffage, fenêtres, câblage, etc... nous préférons nous orienter sur du neuf avec des <strong>partenariats</strong> sérieux afin d'obtenir une garantie du produit.</p>
                <p>Nous travaillons également avec une couturière du village pour son savoir-faire et ses conseils lors du choix des tissus,… et également afin de préconiser les <strong>circuits-court</strong>. Par la suite nous aimerions proposer à notre clientèle des produits liés à notre activité, en partenariat avec d’autres <strong>artisans</strong> (vaisselle en poterie, décoration en macramé, planche à découper,…)</p>
            </div>
        </div>
    </section>
    <section class="layouts">
        <transition name="fade">
            <div id="layout-second-view" v-if="showDescription1  || showDescription2 || showDescription3 || showDescription4">
                <h2>nos différents types de véhicules</h2>
                <div class="layout-description" v-if="showDescription1">
                    <img src="../assets/img/vehicle-1.jpeg" alt="camion aménagé" class="normalize">
                    <div class="layout-description-text">
                        <button class="modal-default-button" @click="showDescription1=false">
                            <div class=cross></div>
                        </button>
                        <img src="../assets/img/vehicle-font.png" alt="véhicule">
                        <h3>Véhicules aménagés</h3>
                        <p>Il nous arrive parfois de reprendre l’aménagement depuis le début (lorsque le client veut remettre son véhicule au gout du jour où lorsque l’aménagement n’est pas conforme aux attentes du nouvel acquéreur)</p>
                    </div>                
                </div>
                <div class="layout-description" v-if="showDescription2">
                    <img src="../assets/img/vehicle-2.jpeg" alt="camion semi aménagés">
                    <div class="layout-description-text">
                        <button class="modal-default-button" @click="showDescription2=false">
                            <div class=cross></div>
                        </button>
                        <img src="../assets/img/vehicle-font.png" alt="véhicule">
                        <h3>Véhicules <br>semi-aménagés</h3>
                        <p>Nous pouvons également partir de véhicules semi aménagés à partir desquels nous réadaptons le confort 
                        et les envies de notre client. Cela nous donne un peu plus de réflexion selon les bases existantes (si les matelas sont déjà faits, ou si une fenêtre se trouve déjà à un emplacement précis).  Cela permet d’apporter au client plus de fonctionnalités et de sécurité sans mettre la main à son portefeuille.
                        </p>
                    </div>                
                </div>
                <div class="layout-description" v-if="showDescription3">
                    <img src="../assets/img/vehicle-3.jpeg" alt="camion vide"  class="normalize">
                    <div class="layout-description-text">
                        <button class="modal-default-button" @click="showDescription3=false">
                            <div class=cross></div>
                        </button>
                        <img src="../assets/img/empty-vehicle.png" alt="véhicule" id="img-vehicle-3">
                        <h3>Véhicules vides</h3>
                        <p>Nous aimons concevoir des véhicules vide, ce qui nous permet de réaliser le projet en intégralité afin de structurer l’aménagement de notre client au plus près de ses attentes tout en utilisant nos méthodes de fabrication.</p>
                    </div>                
                </div>
                <div class="layout-description" v-if="showDescription4">
                    <img src="../assets/img/vehicle-4.jpeg" alt="camping-car">
                    <div class="layout-description-text">
                        <button class="modal-default-button" @click="showDescription4=false">
                            <div class=cross></div>
                        </button>
                        <img src="../assets/img/camping-car.png" alt="véhicule" id="img-vehicle-4">
                        <h3>Camping car</h3>
                        <p>Nous travaillons également sur des bases de camping-car pour réadapter celui-ci à l’attente du client mais également de changer l’utilité de ce dernier pour qu’il ne soit plus camping-car mais qu’il serve de rangement ou d’outil pour des organismes public (comme pour exemple, la mission locale de Lons Le Saunier qui a transformé son camping-car en Bureau).</p>
                    </div>
                </div>
            </div>
            <div id="layout-first-view" v-else >
                <h2>nos différents types de véhicules</h2>
                <div id="layouts-titles" >
                    <button class="layout" @click="showDescription1=true" >
                        <img src="../assets/img/vehicle-font.png" alt="véhicule">
                        <h3>Véhicules aménagés</h3>
                    </button>
                    <button class="layout" @click="showDescription2=true">
                        <img src="../assets/img/vehicle-font.png" alt="véhicule">
                        <h3>Véhicules <br>semi-aménagés</h3>
                    </button>
                    <button class="layout" @click="showDescription3=true">
                        <img src="../assets/img/empty-vehicle.png" alt="véhicule">
                        <h3>Véhicules vides</h3>
                    </button>
                    <button class="layout" @click="showDescription4=true">
                        <img src="../assets/img/camping-car.png" alt="véhicule">
                        <h3>Camping car</h3>
                    </button>
                </div>
            </div>
        </transition>
    </section>
    <section class="more bgc-hooker">
        <h2>Et pour le reste ?</h2>
        <p>Nous proposons de la <strong>pose détaillé</strong> de chaque partie du camion, pose de fenêtre uniquement, lanterneau, isolation, panneaux solaire, chauffage, meuble(s), galerie sur mesure, ameublement de la cabine (étagère à la place de porte document, retravaille du tableau de bord…).</p>
        <p>Aujourd’hui nous travaillons avec des <strong>fournisseurs</strong> auprès desquels nous regroupons les commandes afin d’éviter les allers-retours liés à la livraison.</p>
    </section>
    <section class="contact">
        <div class="contact-img">
        </div>
        <div class="contact-text" >
            <h2>nos tarifs</h2>
            <p>En termes de prix, nous pouvons difficilement fixer un montant étant donné que chaque aménagement est conçu sur mesure et qu’il comporte énormément de variantes (taille du véhicule, ouverture déjà existante ou non, équipement et selon l’arrivage des matières premières récupéré). Il est préférable de nous joindre pour toute demande de tarifs.</p>
            <button class="button-black">
                <router-link to="/contact">nous contacter</router-link>
            </button>
        </div>
    </section>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    data() {
        return {
            showDescription1: false,
            showDescription2: false,
            showDescription3: false,
            showDescription4: false
        }
    },
    methods: {
        scrollToTop() {
            const layouts = this.$refs.layouts;
                layouts.scrollIntoView({ top: 0, behavior: "smooth" });
        }
    },
});
</script>

<style scoped>

#layout-beginning {
    background: url(~@/assets/img/layouts-1.jpeg);
    background-size: cover;
    background-position: 0 -140px;
    background-attachment: fixed;
    height: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#layout-beginning h1 {
    text-transform: uppercase;
    color: white;
    font-size: 44px;
    width: 680px;
    text-align: center;
}
#layout-beginning span {
    text-transform: lowercase;
    font-family: Lora;
    font-style: italic;
    font-size: 38px;
}
#arrow-to-layouts{
    color: white;
    font-size: 25px;
    padding: 4px 13px;
    border: 3px solid white;
    border-radius: 50%;
    transition: rotate 500ms ease-in-out;
}
.reverse {
    rotate: 180deg;
    transition: all 500ms ease-in-out;
}
#layouts {
    height: 540px;
    display: flex;
    justify-content: center;
    align-items: center;
}
#layouts p {
    flex-basis: 42%;
    text-align: center;
    font-size: 20px;
    line-height: 30px;
}
.materials {
    display: flex;
    flex-direction: column;
    gap: 250px;
}
.material {
    display: flex;
    justify-content: space-between;
    height: 860px;
}
.material:last-child {
    flex-direction: row-reverse;
}
.material-image {
    position: relative;
}
.material img {
    object-fit: cover;
    height: 100%;
}
.material:first-child .material-image {
    flex-basis: 60%;
}
.material:last-child .material-image {
    flex-basis: 50%;
}
.material:first-child .material-image img {
    width: 815px;
    max-width: 100%;
}
.material:last-child .material-image img {
    width: 790px;
    max-width: 100%;
}
.material span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 28px;
    text-transform: uppercase;
    text-align: center;
}
.material-description {
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-content: center;
    align-items: center;
}
.material-description p {
    font-weight: 300;
    width: 465px;
}
.material p:first-child {
    border-bottom: 1px black solid;
    padding-bottom: 15px;
    margin-bottom: 40px;
    font-weight: 500;
}
.layouts {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("~@/assets/img/vehicles.png");
    background-size: cover;
    background-position: center;
    height: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    position: relative;
}
.fade-enter-active {
    opacity: 0;
}
.fade-enter-active, .fade-leave-active {
    position: absolute;
    transition: all .5s;
}
.fade-enter-to {
    opacity: 1;
}
.fade-leave-to {
    opacity: 0;
}
#layout-first-view, #layout-second-view, .layout-description-text  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.layout {
    gap: 5px;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.layouts h2 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 70px;
}
#layouts-titles{
    display: flex;
    gap: 15px;
    width: 110%;
}
.layout, .layout-description {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    display: flex;
} 
.layout:nth-child(-n+2) img, .layout-description-text img{
    width: 90px;
    height: 90px;
}
.layout:nth-child(-n+2) img {
    position: absolute;
    top: 70px;
}
.layout:nth-child(3) img, .layout-description-text #img-vehicle-3{
    width: 90px;
    height: 60px;
}
.layout:nth-child(3) img {
    position: absolute;
    top: 90px;
}
.layout:nth-child(4) img, .layout-description-text #img-vehicle-4 {
    width: 90px;
    height: 70px; 
}
.layout:nth-child(4) img {
    position: absolute;
    top: 80px;
}
.layout {
    width: 250px;
    transition: background-color 500ms ease-out;
    height: 250px;
    border: none;
}
.layout:hover {
    background-color: rgba(255, 255, 255, 0.4);
    transition: all 500ms ease-out;
}
.layouts h3 {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    margin: 0;
}
.layout h3 {
    position: absolute;
    top: 160px;
}
.layout-description {
    display: flex;
    width: 65%;
    height: 550px;
    overflow: hidden;
}
.layout-description > img {
    max-width: 55%;
    height: 100%;
    width: 800%;
    object-fit: cover;
}
.layout-description-text {
    padding: 0 50px;
    position: relative;
}
.layout-description-text button {
    position: absolute;
    top: 15px;
    right: 20px;
    width: 20px;
    height: 20px;
    border: none;
    padding: 0;
}
.layout-description-text .cross {
    position: relative;
    width: 20px;
    height: 20px;
}
.layout-description-text .cross::before,
.layout-description-text .cross::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    top: 50%;
    left: 0;
    background-color: white;
    transform: rotate(45deg);
}
.layout-description-text .cross::after {
    transform: rotate(135deg);
}
.layout-description-text h3 {
    border-bottom: 1px white solid;
    width: 100%;
    padding-bottom: 15px;
    margin: 15px 0;
}
.layout-description-text p {
    margin: 0;
}
.more {
    padding: 11% 37%;
}
.more h2 {
    text-transform: uppercase;
    font-family: 'poppins';
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 35px;
}
.more p {
    font-weight: 300;
}
.contact {
    display: flex;
    height: 1100px;
}
.contact-img{
    background: url(~@/assets/img/layouts-4.jpeg);
    background-size: cover;
    background-position: 40% ;
    flex-basis: 150%;
}
.contact-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 45px;
}
.contact h2 {
    font-family: 'poppins';
    font-weight: 600;
    text-transform: uppercase;
    font-size: 22px;
    margin: 0;
}
.contact p {
    width: 48%;
    font-weight: 300;
    text-align: center;
    margin: 0;
}
.button-black a {
    color: black;
    font-size: 150%;
    text-transform: uppercase;
}
.button-black {
    border: 2px solid black;
    color: black;
    border-radius: 50px;
    background-color: transparent;
    position: relative;
    overflow: hidden;
    width: 190px;
    height: 25px;
}
.button-black::before {
    content: "";
    display: block;
    width: 300px;
    height: 50px;
    background-color: black;
    position: absolute;
    top: 0;
    left: -300px;
    z-index: 0;
    transition: all 500ms ease-in;
}
.button-black:hover a{
    position: absolute;
    color: white;
    top: 25%;
    left: 11%;
    transition: all 500ms ease-in-out;
}
.button-black:hover::before{
    left: 0;
    transition: all 500ms ease-in-out;
}
@media (max-width: 1300px) {
    .layout {
        width: 190px;
        height: 220px;
    }
    #layouts-titles {
        justify-content: space-between;
    }
}
@media (max-width: 1100px) {
    #layout-beginning{
        background-position: -400px -20px;
        height: 900px;
    }
    #layout-beginning h1 {
        font-size: 20px;
        width: auto;
    }
    #layout-beginning span {
        font-size: 26px;
    }
    #arrow-to-layouts {
        font-size: 10px;
        padding: 2px 6px;
        border: 1px solid white;
    }
    #layouts {
        height: 260px;
    }
    #layouts p {
        font-size: 11px;
        line-height: 16.5px;
    }
    .materials, .material {
        gap: 0;
    }
    .material, .material:last-child {
        flex-direction: column;
        height: auto;
        gap: 60px;
        padding-bottom: 60px;
    }
    .material:first-child .material-image, .material:last-child .material-image{
        flex-basis: auto;
    }
    .material:first-child .material-image img, .material:last-child .material-image img {
        width: 100%;
        height: 197px;
    }
    .material-description {
        width: 100%;
    }
    .material p:first-child {
        padding-bottom: 25px;
        margin-bottom: 25px;
    }
    .material p {
        width: 280px;
        margin: 10px 0;
    }
    .layouts {
        height: 600px;
        position: relative;
    }
    .layouts h2 {
        font-weight: 600;
        font-size: 14px;
        margin: 0;
    }
    #layouts-titles {
        margin-top: 47px;
        width: 160%;
        flex-wrap: wrap;
        gap: 20px;
    }
    .layout {
        width: 34%;
        height: 128px;
    }
    .layout:nth-child(-n+2) img {
        width: 50px;
        height: 50px;
    }
    .layout:nth-child(-n+2) img {
        top: 25px;
    }
    .layout:nth-child(3) img {
        width: 50px;
        height: 35px;
        top: 37px;
    }
    .layout:nth-child(4) img {
        width: 46px;
        height: 40px;
        top: 32px;
    }
    .layout h3 {
        top: 80px;
    }
    .layouts h3 {
        font-size: 14px;
        line-height: 17px;
    }
    #layout-second-view {
        position: relative;
    }
    .layout-description {
        width: 75%;
        height: 447px;
        flex-direction: column;
        margin-top: 20px;
    }
    .layout-description > img {
        width: 100%;
        max-width:none ;
        max-height: 42%;
    }
    .layout-description-text h3 {
        margin-top: 0;
    }
    .layout-description-text {
        padding: 7% 50px;
        position: initial;
        height: 100%;
    }
    .layout-description-text img {
        display: none;
    }
    .layout-description-text button {
        top: -1%;
    }
    .more {
        padding: 17% 20%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .more h2 {
        font-size: 14px;
    }
    .more p {
        width: 280px;
    }
    .contact {
        flex-direction: column;
        height: auto;
    }
    .contact-img {
        flex-basis: auto;
        height: 215px;
        background-position: 0 0%;
    }
    .contact-text {
        padding: 60px 0 80px;
        gap: 25px;
    }
    .contact h2 {
        font-size: 14px;
    }
    .contact p {
        width: 300px;
    }
    .button-black {
        width: 165px;
        height: 30px;
        padding: 0;
    }
    .button-black a {
        font-size: 14px;
        font-weight: 500;
    }
}
@media (max-width: 900px) {
    .layout-description > img {
        max-height: 35%;
    }
}
@media (max-width: 800px) {
    .layout-description p {
        font-size: 13px;
        line-height: 18px;
    }
}
@media (max-width: 500px) {
    .layout-description > img {
        max-height: 25%;
    }
    .layout-description > .normalize {
        max-height: 35%;
    }
}
@media (max-width: 450px) {
    #layouts-titles {
        width: 100%;
    }
}
@media (max-width: 400px) {
    .layout-description > img {
        max-height: 15%;
    }
}

</style>