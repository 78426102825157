<template>
    <section>
        <div class="contact-img">
            <h2>Retrouvez-nous</h2>
            <span>Tél : 07.88.33.97.41</span>
            <span><a href="mailto:artisanatvan@gmail.com">artisanatvan@gmail.com</a></span>
            <span>15 sous le Château, Clairvaux-les-Lacs</span>
            <div class="links">
                <a href="https://www.facebook.com/profile.php?id=100086455113900">
                    <img src="../assets/img/fb-white.png" alt="facebook">
                </a>
                <a href="https://instagram.com/artisanatvan?igshid=ZDdkNTZiNTM=">
                    <img src="../assets/img/insta-white.png" alt="insta">
                </a>
            </div>
        </div>
        <div class="form-bloc">
            <img src="../assets/img/contact.gif" alt="logo artisanat van">
            <h1>On discute de votre projet ?</h1>
            <form ref="contactForm" @submit.prevent="sendEmailContact" method="post" >
                <input type="text" v-model="nom" name="nom" placeholder="Nom *" required>
                <input type="text" v-model="prenom" name="prenom" placeholder="Prénom *" required>
                <input type="mail" v-model="mail" name="email" placeholder="Adresse mail *" required>
                <input type="tel" v-model="tel" name="tel" placeholder="Téléphone *" required>
                <input type="text" v-model="vehicle" name="vehicle" placeholder="Type de véhicule *" required>
                <fieldset >
                    <legend @click="showOptions()">
                        <span >{{ Options }}</span>
                        <div id="down-chevron" class="down-chevron"></div>
                    </legend>
                    <div id="possible-values" class="hidden-options">
                        <div id="value-container" class="hidden-options"> 
                            <div class="possible-value hidden-value">
                                <input type="checkbox" @change="updateselectedOptions" :value="value1" class="checkbox0" id="electricity" name="electricity">
                                <label for="electricity" id="label0" >électricité</label>
                            </div>
                            <div class="possible-value hidden-value">
                                <input type="checkbox" @change="updateselectedOptions" :value="value2" class="checkbox1"  id="heat" name="heat">
                                <label for="heat" id="label1" >chauffage</label>
                            </div>
                            <div class="possible-value hidden-value">
                                <input type="checkbox" @change="updateselectedOptions" :value="value6" class="checkbox2"  id="window" name="window">
                                <label for="window" id="label2" >ouverture de fenêtre</label>
                            </div>
                            <div class="possible-value hidden-value">
                                <input type="checkbox" @change="updateselectedOptions" :value="value4" class="checkbox3"  id="solar-panels" name="solar-panels">
                                <label for="solar-panels" id="label3" >panneaux solaires</label>
                            </div>
                            <div class="possible-value hidden-value">
                                <input type="checkbox" @change="updateselectedOptions" :value="value5" class="checkbox4"  id="layout" name="layout">
                                <label for="layout" id="label4" >aménagement</label>
                            </div>
                            <div class="possible-value hidden-value">
                                <input type="checkbox" @change="updateselectedOptions" :value="value3" class="checkbox5"  id="isolation" name="isolation">
                                <label for="isolation" id="label5" >isolation</label>
                            </div>
                            <div class="possible-value hidden-value">
                                <input type="checkbox" @change="updateselectedOptions" :value="value7" class="checkbox6"  id="cb" name="cb">
                                <label for="cb" id="label6" >camping box</label>
                            </div>
                            <div class="possible-value hidden-value">
                                <input type="checkbox" @change="updateselectedOptions" :value="value8" class="checkbox7" id="water" name="water">
                                <label for="water" id="label7" >système d'eau</label>
                            </div>
                        </div>
                    </div>                   
                </fieldset>
                <textarea placeholder="Quelques mots ?" v-model="comment" name="comment" id="comment" cols="30" rows="6"></textarea>
                <div class="button">
                    <input type="submit" value="ENVOYER">
                    <span id="confirmation" class="confirmation">Votre message a été envoyé avec succès</span>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import { defineComponent } from "vue";
import emailjs from '@emailjs/browser';

export default defineComponent({
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    data() {
        return {
            Options: "Options",
            selectedOptions: [],
            nom: "",
            prenom: "",
            mail: "",
            tel: "",
            vehicle: "",
            comment: "",
            value1: 'Électricité',
            value2: 'Chauffage',
            value3: 'Isolation',
            value4: 'Panneaux Solaires',
            value5: 'Aménagement',
            value6: 'Ouverture De Fenêtre',
            value7: 'Camping Box',
            value8: 'Système D\'eau',
            hiddenOptions: true
        }
    },
    methods: {
        showOptions() {
            const options = document.getElementById('possible-values');
            const optionsContainer = document.getElementById('value-container');
            const optionsList = document.getElementsByClassName('possible-value');
            const chevron = document.getElementById('down-chevron');
            const fieldset = document.querySelector('fieldset')
            const nextInput = document.getElementById('comment')
            
            if (this.hiddenOptions) {
                options.classList.remove('hidden-options')
                optionsContainer.classList.remove('hidden-options')
                chevron.classList.add('reverse')
                fieldset.classList.add('bigger-fieldset')
                nextInput.classList.add('diminish')
                for( let i=0; i<optionsList.length; i++) {
                    let timeout = i*10;
                    setTimeout(() => {
                        optionsList[i].classList.remove('hidden-value')
                    }, timeout);
                }
                this.hiddenOptions = false
            }else {
                options.classList.add('hidden-options')
                optionsContainer.classList.add('hidden-options')
                chevron.classList.remove('reverse')
                fieldset.classList.remove('bigger-fieldset')
                nextInput.classList.remove('diminish')
                setTimeout(() => {
                    optionsContainer.classList.add('hidden-options');
                    }, 300);
                for( let i=0; i<optionsList.length; i++) {
                    let timeout = i*10;
                    setTimeout(() => {
                        optionsList[i].classList.add('hidden-value')
                    }, timeout);
                }
                this.hiddenOptions = true
            }            
        },
        updateselectedOptions(event) {
            const value = event.target.value;

            for (let i = 0; i <= 7; i++) {
                let checkbox = document.getElementsByClassName("checkbox" + i)[0];
                let label = document.getElementById("label" + i);

                if (checkbox.checked) {
                    label.classList.add('checked')
                } else {
                    label.classList.remove('checked')
                }
            }
            if (event.target.checked) {
                this.selectedOptions.push(value);            
            } else {
                this.selectedOptions = this.selectedOptions.filter(
                (selectedValue) => selectedValue !== value
                );
            }
            if ( this.Options==="" ) {
                this.Options = "Options"
            }
            let optionNumber = this.selectedOptions.length

            if (optionNumber === 0 ) {
                this.Options = "Options"
            }else if (optionNumber === 1){
                this.Options = `Option ( 1 sélectionnée )`
            }else {
                this.Options = `Options ( ${optionNumber} sélectionnées )`
            }
        },
        sendEmailContact() {
            let confirmationMessage = document.getElementById('confirmation')
            let finalArray = {}

            for( let i=0; i<this.$refs.contactForm.length; i++) {
                
                let key = this.$refs.contactForm[i].name
                let element = document.getElementById(`${key}`)
                
                switch (key) {
                    case 'nom' :
                        finalArray.nom = this.$refs.contactForm[i].value
                        break;
                    case 'prenom' :
                        finalArray.prenom = this.$refs.contactForm[i].value
                        break;
                    case 'email' :
                        finalArray.mail = this.$refs.contactForm[i].value
                        break;
                    case 'tel' :
                        finalArray.tel = this.$refs.contactForm[i].value
                        break;
                    case 'vehicle' :
                        finalArray.vehicle = this.$refs.contactForm[i].value
                        break;
                    case 'electricity' :
                        if (element.checked) {
                            finalArray.electricity = "Oui"
                        }else finalArray.electricity = "Non"
                        break;
                    case 'heat' :
                    if (element.checked) {
                            finalArray.heat = "Oui"
                        }else finalArray.heat = "Non"
                    break;
                    case 'isolation' :
                    if (element.checked) {
                            finalArray.isolation = "Oui"
                        }else finalArray.isolation = "Non"
                    break;
                    case 'solar-panels' :
                    if (element.checked) {
                            finalArray.solarPanels = "Oui"
                        }else finalArray.solarPanels = "Non"
                    break;
                    case 'layout' :
                    if (element.checked) {
                            finalArray.layout = "Oui"
                        }else finalArray.layout = "Non"
                    break;
                    case 'window' :
                    if (element.checked) {
                            finalArray.window = "Oui"
                        }else finalArray.window = "Non"
                    break;
                    case 'cb' :
                    if (element.checked) {
                            finalArray.cb = "Oui"
                        }else finalArray.cb = "Non"
                    break;
                    case 'water' :
                    if (element.checked) {
                            finalArray.water = "Oui"
                        }else finalArray.water = "Non"
                    break;
                    case 'comment' :
                        finalArray.comment = this.$refs.contactForm[i].value
                        break;
                    default:
                        break;
                }
                
            }
            
            emailjs.send('service_0tslu9b', 'template_op2pzya', finalArray, 'xL9s7MhAcQ0ksj7jL')
                .then((result) => {
                    console.log('SUCCESS!', result.text);
                    confirmationMessage.classList.add('display-confirmation')
                }, (error) => {
                    console.log('FAILED...', error.text);
            });
        }
    },
});
</script>
<style scoped>

section {
    display: flex;
    padding-left: 150px;
    height: 1200px;
    background-color: #192929;
    color: white;
}
.contact-img {
    width: 625px;
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("~@/assets/img/contact.jpeg");
    background-size: cover;
    background-position: 37%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contact-img h2 {
    text-transform: uppercase;
    font-size: 20px;
    font-style: semi-bold;
}
.contact-img span {
    font-size: 18px;
    font-weight: 400;
}
.contact-img span a {
    color: white;
}
.links {
    display: flex;
    justify-content: center;
    width: 180px;
    padding-top: 20px;
    height: 50px;
    gap: 20px;
    align-items: center;
}
.links img {
    width: 37px;
    height: 37px;
}
.links img:hover {
    width: 45px;
    height: 45px;
    transition: all 500ms ;
}
.form-bloc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 150px 1%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
}
.form-bloc img {
    position: absolute;
    width: 700px;
    top: 65px;
    height: auto;
}
.form-bloc h1 {
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-size: 21px;
    padding-bottom: 40px;
    z-index: 1;
}
form {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    position: relative;
    gap: 30px;
    height: 400px;
    width: 800px;
}
input::placeholder, textarea::placeholder {            

    opacity: 1;
}
form input, form fieldset, form textarea {
    width: 40%;
    border: 2px white solid;
    border-radius: 50px;
    background-color: transparent;
    color: white;
    padding: 0 0 0 30px;
}

form input, form fieldset {
    flex-basis: 15%;
}
form fieldset {
    position: absolute;
    height: 60px;
    top: 28%;
    right: 4%;
    transition: height 500ms ease-in;
}
.bigger-fieldset {
    height: 72%;
    transition: all 500ms ease-out;
}
fieldset legend {
    position: absolute;
    top: 20px;
    display: flex;
    justify-content: space-between;
    width: 80%;
}
.down-chevron {
    position: relative;
    width: 20px;
    height: 20px;
    rotate: 0deg;
    transition: rotate 500ms;
}
legend .down-chevron::before,
legend .down-chevron::after {
    content: "";
    position: absolute;
    width: 70%;
    height: 2px;
    top: 50%;
    background-color: white;
    transform: rotate(45deg);
}
legend .down-chevron::after {
    transform: rotate(135deg);
    left: 10px;
}
.reverse {
    rotate: 180deg;
    transition: all 500ms ease;
}
#possible-values {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    top: 45px;
    left: -3px;
}
#possible-values::before {
    content: "";
    display: block;
    width: 85%;
    border-bottom:1px white solid;
    position: absolute;
    top: 15px;
}
fieldset > div {
    padding: 35px 0;
    height: fit-content;
    transition: all 500ms ease-in-out;
}
.hidden-options {
    z-index: -1;
    padding: 0;
    height: 0px;
    transition: all 500ms ease-in-out;
}
#value-container {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    height: 150px;
    width: 100%;
    padding-left: 10px;
    gap: 15px;
}
.possible-value {
    position: relative;
    padding: 10px;
    top: 0px;
    transition: top 500ms ease-in-out;
    width: fit-content;
    padding: 0;
}
.possible-value label {
    text-transform: capitalize;
    padding: 3px 10px;
    border-radius: 25px;
}
.possible-value input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.checked {
    background-color: #fff;
    color: #192929;
    transition: all 500ms ease;
}
.hidden-value {
    top: -350px;
    transition: all 500ms ease-in-out;
}
form textarea {
    padding-top: 20px;
    transition: all 500ms ;
}
.diminish {
    opacity: 0;
    z-index: -1;
    transition: all 250ms;
}
.button {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: -50%;
    gap: 40px;
    height: 150px;
}
.button:hover input{
    padding: 11.5px 50px;
    font-size: 22px;
    transition: all 500ms ease;
}
.button input {
    padding: 10px 40px;
    width: 100px;
    background-color: #fff;
    color: black;
    font-size: 20px;
    font-weight: 500;
}
.confirmation {
    font-size: 16px;
    font-style: italic;
    opacity: 0;
}
.display-confirmation {
    opacity: 1;
    transition: all 500ms ease-out 250ms;
}
@media (max-width: 1550px) {
    .form-bloc {
        width: 90%;
    }
    form {
        width: 100%;
    }
}
@media (max-width: 1400px) {
    form fieldset {
        right: 3%;
    }
}
@media (max-width: 1250px) {
    form fieldset {
        right: 2%;
    }
}
@media (max-width: 1100px) {
    section {
        padding: 80px 0 0;
        height: auto;
        flex-direction: column;
    }
    .contact-img {
        width: 100%;
        height: 380px;
    }
    .contact-img h2, .contact-img span{
        font-size: 14px;
    }
    .contact-img h2 {
        font-weight: 600;
    }
    .links img {
        width: 32px;
        height: 32px;
    }
    .form-bloc {
        padding: 160px 0 200px;
        height: fit-content;
    }
    .form-bloc img {
        width: 500px;
        top: 0;
    }
    .form-bloc h1 {
        font-size: 14px;
        padding: 0;
        margin: 30px;
        font-weight: 600;
    }
    form {
        width: 100%;
        flex-wrap: nowrap;
        align-items: center;
        height: fit-content;
    }
    form input, form fieldset, form textarea {
        width: 70%;
        flex-basis: auto;
        box-sizing: border-box;
        border: 1px white solid;
        font-size: 11px;
    }
    form input, form fieldset {
        position: relative;
        top: 0;
        right: 0;
        height: 40px;
    }
    #possible-values::before {
        top: -7px;
    }
    fieldset > div {
        padding: 10px 0;
    }
    #value-container {
        flex-wrap: nowrap;
        height: 240px;
        width: 70%;
    }
    .bigger-fieldset {
        height: 320px;
    }
    .diminish {
        opacity: inherit;
        z-index: 0;
    }
    form textarea { 
        height: 130px;
        border-radius: 35px;
    }
    fieldset legend {
        top: 11px;
    }
    .button {
        bottom: -33%;
        gap: 25px;
    }
    .button input {
        padding: 0;
        font-size: 14px;
        font-weight: 500;
        height: 30px;
    }
    #confirmation {
        font-size: 13px;
        text-align: center;
        width: 230px;
    }
}

</style>
