<template>
    <section class="gallery-title">
        <h1>galerie <span>photos</span><br>de nos aménagements</h1>
    </section>
    <section class="gallery" ref="gallery">
        <button ref="scrollTopButton" @click="scrollToTop" class="gallery-button">
            <font-awesome-icon icon="fa-solid fa-arrow-down" />
        </button>        
            <div class="gallery-container">
                <div class="gallery-row-1">
                    <div class="gallery-col-1">
                        <img @click="openGallery(1)" id="button-1" class="img1" src="../assets/img/gallery-1.jpeg" alt="lit dans un van">              
                        <img @click="openGallery(2)" id="button-2" class="img2" src="../assets/img/gallery-2.jpeg" alt="robinet naturel">
                    </div>
                    <div class="gallery-col-2">
                        <img @click="openGallery(3)" id="button-3" class="img3" src="../assets/img/gallery-3.jpeg" alt="lavabo intégré">
                        <img @click="openGallery(4)" id="button-4" class="img4" src="../assets/img/gallery-4.jpeg" alt="van réaménagé">
                    </div>
                    <img @click="openGallery(5)" id="button-5" class="img5" src="../assets/img/gallery-5.jpeg" alt="amènagement en bois">
                </div>
                <div class="gallery-row-2">
                    <div class="gallery-col-1">
                        <img @click="openGallery(6)" id="button-6" class="img6" src="../assets/img/gallery-6.jpeg" alt="canapé et petite table">
                        <img @click="openGallery(7)" id="button-7" class="img7" src="../assets/img/gallery-10.jpeg" alt="tiroirs">
                    </div>
                    <div class="gallery-col-2">
                        <img @click="openGallery(8)" id="button-8" class="img8" src="../assets/img/gallery-8.jpeg" alt="nécessaire de camping">
                        <img @click="openGallery(9)" id="button-9" class="img9" src="../assets/img/gallery-9.jpeg" alt="canapé et table">
                    </div>
                </div>
                <div class="gallery-row-3">
                    <div class="gallery-col-1">
                        <img @click="openGallery(10)" id="button-10" class="img10" src="../assets/img/gallery-7.jpeg" alt="lit déplaçable intégré">
                        <img @click="openGallery(11)" id="button-11" class="img11" src="../assets/img/gallery-11.jpeg" alt="petite porte en bois">
                    </div>
                    <div class="gallery-col-2">
                        <img @click="openGallery(12)" id="button-12" class="img12" src="../assets/img/gallery-14.jpeg" alt="petit support dépliable/table">
                        <img @click="openGallery(13)" id="button-13" class="img13" src="../assets/img/gallery-12.jpeg" alt="aménagement tout en bois">
                    </div>
                </div>
                <div class="gallery-row-4">
                    <img @click="openGallery(14)" id="button-14" class="img14" src="../assets/img/gallery-13.jpeg" alt="intérieur bétonné">
                    <img @click="openGallery(15)" id="button-15" class="img15" src="../assets/img/gallery-17.jpeg" alt="construction de l'intérieur d'un van">
                    <img @click="openGallery(16)" id="button-16" class="img16" src="../assets/img/gallery-15.jpeg" alt="supports technique et pratique">
                </div>
                <div class="gallery-row-5">
                    <img @click="openGallery(17)" id="button-17" class="img17" src="../assets/img/vehicle-4.jpeg" alt="table, canapé et lumière">
                    <div class="gallery-col-2">
                        <img @click="openGallery(18)" id="button-18" class="img18" src="../assets/img/gallery-18.jpeg" alt="lumière, miroir et plante">
                        <img @click="openGallery(19)" id="button-19" class="img19" src="../assets/img/gallery-16.jpeg" alt="canapé, lumière et intérieur tout en bois">
                    </div>                    
                </div>
            </div>
            <carousel  :items-to-show="1.22" :items-to-scroll="2" :transition="400">
                <slide :key="slide" class="slides">
                    <img @click="openGallery(20)" id="button-20" class="img1" src="../assets/img/gallery-1.jpeg" alt="lit dans un van">
                </slide>
                <slide :key="slide1" class="slides">
                    <img @click="openGallery(21)" id="button-21" class="img2" src="../assets/img/gallery-3.jpeg" alt="lavabo intégré">
                </slide>
                <slide :key="slide2" class="slides">
                    <img @click="openGallery(22)" id="button-22" class="img3" src="../assets/img/gallery-2.jpeg" alt="robinet naturel">
                </slide>
                <slide :key="slide3" class="slides">
                    <img @click="openGallery(23)" id="button-23" class="img4" src="../assets/img/gallery-4.jpeg" alt="lit déplaçable intégré">
                </slide>
                <slide :key="slide4" class="slides">
                    <img @click="openGallery(24)" id="button-24" class="img5" src="../assets/img/gallery-5.jpeg" alt="amènagement en bois">
                </slide>
                <slide :key="slide5" class="slides flex-col">
                    <img @click="openGallery(25)" id="button-25" class="img6" src="../assets/img/gallery-6.jpeg" alt="canapé et petite table">
                    <img @click="openGallery(26)" id="button-26" class="img7" src="../assets/img/gallery-14.jpeg" alt="petit support dépliable/table">
                </slide>
                <slide :key="slide6" class="slides">
                    <img @click="openGallery(27)" id="button-27" class="img8" src="../assets/img/gallery-8.jpeg" alt="nécessaire de camping">
                </slide>
                <slide :key="slide7" class="slides">
                    <img @click="openGallery(28)" id="button-28" class="img9" src="../assets/img/gallery-10.jpeg" alt="tiroirs">
                </slide>
                <slide :key="slide8" class="slides flex-col" >
                    <img @click="openGallery(29)" id="button-29" class="img10" src="../assets/img/gallery-9.jpeg" alt="canapé et table">
                    <img @click="openGallery(30)" id="button-30" class="img11" src="../assets/img/gallery-17.jpeg" alt="construction de l'intérieur d'un van">
                </slide>
                <slide :key="slide9" class="slides">
                    <img @click="openGallery(31)" id="button-31" class="img12" src="../assets/img/gallery-7.jpeg" alt="lit déplaçable intégré">
                </slide>
                <slide :key="slide10" class="slides">
                    <img @click="openGallery(32)" id="button-32" class="img13" src="../assets/img/gallery-11.jpeg" alt="petite porte en bois">
                </slide>
                <slide :key="slide11" class="slides flex-col">
                    <img @click="openGallery(33)" id="button-33" class="img14" src="../assets/img/gallery-15.jpeg" alt="supports technique et pratique">
                    <img @click="openGallery(34)" id="button-34" class="img15" src="../assets/img/gallery-13.jpeg" alt="intérieur bétonné">
                </slide>
                <slide :key="slide12" class="slides">
                    <img @click="openGallery(35)" id="button-35" class="img16" src="../assets/img/gallery-12.jpeg" alt="aménagement tout en bois">
                </slide>
                <slide :key="slide13" class="slides flex-col">
                    <img @click="openGallery(36)" id="button-36" class="img17" src="../assets/img/gallery-16.jpeg" alt="canapé, lumière et intérieur tout en bois">
                    <img @click="openGallery(37)" id="button-37" class="img18" src="../assets/img/gallery-18.jpeg" alt="lumière, miroir et plante">
                </slide>
                <slide :key="slide14" class="slides">
                    <img @click="openGallery(38)" id="button-38" class="img19" src="../assets/img/vehicle-4.jpeg" alt="van réaménagé">
                </slide>
                <template #addons>
                    <navigation />
                </template>
            </carousel>
    </section>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { defineComponent } from "vue";
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default defineComponent({
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data() {
        return {
            image: "",
            button: "",
            newDiv: "",
            newImage: ""
        }
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll() {
            const scrollBtn = this.$refs.scrollTopButton;
            if (window.scrollY > 0) {
                scrollBtn.classList.add("reverse");
            } else {
                scrollBtn.classList.remove("reverse");
            }
        },
        scrollToTop() {
            const gallery = this.$refs.gallery;
            if (window.scrollY > 0) {
                window.scrollTo({ top: 0, behavior: "smooth" });
            } else {
                gallery.scrollIntoView({ top: 0, behavior: "smooth" });
            }
        },
        openGallery(n) {
            let galleryButton = document.querySelector('.gallery-button')
            let button = document.getElementById('button-'+n)
            let modal = document.createElement('div');
            let img = document.createElement('img');
            let crossButton = document.createElement('button')
            let relative = document.createElement('div')      

            crossButton.classList.add('cross-button')
            crossButton.innerHTML = `<div class="cross"></div>`
            relative.classList.add('flex-center')           
            modal.className = 'modal'
            img.src = button.src;
            relative.appendChild(img);
            galleryButton.style.opacity = "0"

            if(n<20) { 
                relative.appendChild(crossButton)
                crossButton.onclick = function() {
                    this.closeGallery()
                }.bind(this)
            }else {
                modal.onclick = function() {
                    this.closeGallery()
                }.bind(this)
            }

            modal.appendChild(relative)
            document.body.appendChild(modal);
            this.newDiv = modal;
            this.newImage = img;      
            
            
        },
        closeGallery() {
            let galleryButton = document.querySelector('.gallery-button')

            this.newImage.remove();
            this.newDiv.remove();
            galleryButton.style.opacity = "1"    

        }
    },
});

</script>
 
<style>

.gallery-title {
    padding: 320px 0;
}
.gallery-title h1 {
    text-transform: uppercase;
    text-align: center;
    font-size: 38px;
    font-weight: lighter;
    font-size: 34px;
}
.gallery-title h1 span {
    font-family: Lora;
    text-transform: lowercase;
    font-style: italic;
    font-size: 38px;
}
.gallery img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.gallery-button {
    position: fixed;
    z-index: 1;
    bottom: 10%;
    right: 7%;
    background-color: #fff;
    color: black;
    font-size: 150%;
    padding: 7px 16px;
    border-radius: 50%;
    transition: rotate 500ms ease-in-out;
}
.reverse {
    rotate: 180deg;
    transition: all 500ms ease-in-out;
    opacity: 0;
}
.gallery-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.gallery-row-1, .gallery-row-2, .gallery-row-3, .gallery-row-4, .gallery-row-5 {
    display: flex;
    gap: 20px;
}
.gallery-col-1, .gallery-col-2 {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.gallery-row-1 {
    height: 915px;
}
.gallery-row-1 .gallery-col-1 {
    flex-basis: 28%;
}
.gallery-row-1 .img1 {
    height: 33%;
}
.gallery-row-1 .img2 {
    height: 65%;
}
.gallery-row-1 .gallery-col-2 {
    flex-basis: 35%;
}
.gallery-row-1 .img3 {
    height: 55%;
}
.gallery-row-1 .img4 {
    height: 45%;
}
.gallery-row-1 .img5 {
    width: 35%;
    height: 100%;
}

.gallery-row-2 {
    height: 1110px;
}
.gallery-row-2 .gallery-col-1 {
    flex-basis: 37%;
}
.gallery-row-2 .img6 {
    height: 35%;
}
.gallery-row-2 .img7 {
    height: 65%;
}
.gallery-row-2 .gallery-col-2 {
    flex-basis: 62%;
}
.gallery-row-2 .img8 {
    height: 60%;
}
.gallery-row-2 .img9 {
    height: 38%;
}

.gallery-row-3 {
    height: 1440px;
}
.gallery-row-3 .gallery-col-1 {
    flex-basis: 55%;
}
.gallery-row-3 .img10 {
    height: 44%;
}
.gallery-row-3 .img11 {
    height: 54.5%;
}
.gallery-row-3 .gallery-col-2 {
    flex-basis: 44%;
}
.gallery-row-3 .img12 {
    height: 40%;
}
.gallery-row-3 .img13 {
    height: 60%;
}

.gallery-row-4 {
    height: 535px;
}
.gallery-row-4 .img14, .gallery-row-4 .img16 {
    width: 30%;
}
.gallery-row-4 .img15 {
    width: 37%;
}
.gallery-row-4 .image-button {
    height: 100%;
}

.gallery-row-5 {
    height: 905px;
}
.gallery-row-5 .img17 {
    flex-basis: 48%;
    height: 100%;
}
.gallery-row-5 .gallery-col-2 {
    flex-basis: 52%;
}
.gallery-row-5 .img18 {
    height: 55%;
}
.gallery-row-5 .img19 {
    height: 42.5%;
}
.carousel {
    display: none;
}
.image-button {
    padding: 0;
    border: 0;
    display: flex;
    justify-content: center; 
    align-items: center; 
    width: 100%;
}
.image-button img {
    height: 100%;
    max-width: 100%;
    position: relative;
}
.modal {
    position: fixed;
    padding: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    border: 0;
}
.flex-center {
    position: relative;
    display: flex;
    justify-content: center;
    max-height: 70%;
}
.modal img {
    object-fit: cover;
    max-width: 70%;    
}
.cross-button {
    position: absolute;
    padding: 0;
    top: -8%;
    right: 15%;
    border: 0;
    opacity: 1;
    display: block;
}
@media (min-width: 2400px) {
    .gallery-row-1 .img4 {
        height: 42.5%;
    }
}
@media (min-width: 2200px) {
    .gallery-row-2 .img7 {
        height: 63%;
    }
}
@media (max-width: 450px) {
    .carousel {
        display: block;
    }
    .gallery-button, .gallery-container {
        display: none;
    }
    .gallery-title {
        padding: 170px 0;
    }
    .gallery-title h1 {
        font-size: 20px;
    }
    .gallery-title h1 span {
        font-size: 26px;
    }
    .carousel, .carousel__viewport {
        height: 500px;
        overflow: auto;
    }
    .carousel__next, .carousel__prev {
        background-color: white;
        border-radius: 50%;
        padding: 2px;
    }
    .carousel__track {
        gap: 10px;
    }
    .flex-col {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    img, .slides {
        height: 500px;
    }
    :deep .carousel__slide {
        align-items: flex-start;
    }
    .slides {
        width: fit-content !important;
    }
    .carousel__slide .img1 {
        width: 395px;
    }
    .carousel__slide .img2 {
        width: 333px;
    }
    .carousel__slide .img3 {
        width: 258px;
    }
    .carousel__slide .img4 {
        width: 382px;
    }
    .carousel__slide .img5 {
        width: 321px;
    }
    .carousel__slide .img6, .carousel__slide .img7 {
        width: 355px;
    }
    .carousel__slide .img6 {
        height: 220px;
    }
    .carousel__slide .img7 {
        height: 272px;
    }
    .carousel__slide .img8 {
        width: 459px;
    }
    .carousel__slide .img9 {
        width: 267px;
    }
    .carousel__slide .img10, .carousel__slide .img11 {
        width: 353px;
    }
    .carousel__slide .img10 {
        height: 238px;
    }
    .carousel__slide .img11 {
        height: 250px
    }
    .carousel__slide .img12 {
        width: 413px;
    }
    .carousel__slide .img13 {
        width: 318px;
    }
    .carousel__slide .img14, .carousel__slide .img15 {
        width: 303px;
    }
    .carousel__slide .img14 {
        height: 181px;
    }
    .carousel__slide .img15 {
        height: 311px;
    }
    .carousel__slide .img16 {
        width: 267px;
    }
    .carousel__slide .img17, .carousel__slide .img18 {
        width: 297px;
    }
    .carousel__slide .img17 {
        height: 155px;
    }
    .carousel__slide .img18 {
        height: 334px;
    }
    .carousel__slide .img19 {
        width: 392px;
    }
    .modal img {
        max-width: 90%;    
    }
}

</style>
