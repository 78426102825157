<template>
    <section id="workshop-img">
    </section>
    <section class="bgc-brick-red workshop-title">
        <h1>notre atelier</h1>
        <p>Découvrez notre lieu de création en plein coeur du Jura</p>
    </section>
    <section class="workshops">
        <div class="workshop">
            <div class="workshop-description" > 
                <p><strong>Pour réaliser nos ouvrages, tout passe par une bonne organisation qui elle-même commence  par un lieu de travail saint.</strong></p>
                <p>Notre espace de travail est basé sur une ambiance chaleureuse et musicale dans un environnement saint pour une dynamique de travail agréable, non stressante mais également sécurisante. Nous avons accès à l’équipement de protection nécessaire pour pallier les risques liés à la santé et à l’utilisation des machines.</p>
                <p>Dans ces conditions nous pouvons exercer notre métier, notre passion avec beaucoup de créativité et d’envies. Nous gardons un maximum de transparence vis-à-vis de notre travail par rapport au client (visibilité des matériaux récupérés, fournisseurs, espace de travail ordonné,…)</p>
            </div>
            <div class="workshop-img">
                <div class="workshop-img" id="workshop-parallax-1">
                </div>
            </div>
        </div>
        <div class="workshop">
            <div class="workshop-img">
                <img id="workshop-parallax-2" src="../assets/img/workshop-3.jpeg" alt="sciage de bois en plein air">
            </div>            
            <div class="workshop-description" >
                <p>Dans un futur proche nous aimerions acquérir un bâtiment, afin de pouvoir poursuivre notre activité dans les meilleures conditions possible, et de pouvoir lui apporter les modifications nécessaires par rapport à nos envies pour un confort de travail optimum. Que ce soit au niveau du stockage des matières premières, de l’agencement du parc machine où encore de la réalisation d’un lieu chaleureux pour vous recevoir.</p>
            </div>
        </div>
    </section>
    <section class="workshop-pos bgc-brick-red">
        <div>
            <h2>pour mieux nous situer</h2>
            <address>
                <span>15 Sous le Château</span> 
                <span>39130, Clairvaux-les-Lacs</span>
            </address>
            <p>Venez découvrir notre atelier situé entre Clairvaux les Lacs et Cogna, proche de l’entreprise Pierre Bessard.</p>
            <button class="button-white">
                <router-link to="/contact">nous contacter</router-link>
            </button>
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2742.4713984049313!2d5.751112615885774!3d46.57791766645913!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478ce713c2d22bd1%3A0x558a3d599aa6afe9!2s15%20Sous%20le%20Ch%C3%A2teau%2C%2039130%20Clairvaux-les-Lacs!5e0!3m2!1sfr!2sfr!4v1674213840267!5m2!1sfr!2sfr" id="google-map" allowfullscreen="true" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </section>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll() {
            let scrollPosition = window.pageYOffset;
            const parallaxTwo = document.getElementById('workshop-parallax-2')
            parallaxTwo.style.transform = `translateY(${(scrollPosition-1600)*.2}px)`
        }
    },
});
</script>

<style scoped>
#workshop-img {
    height: 689px;
    background: url(~@/assets/img/workshop-1.jpeg);
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    animation: workshop-pop-in 2s 0s ease-in;
}
@keyframes workshop-pop-in {
    0%, 30% {
        height: 900px;
    }to {
    }
}
.workshop-title {
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
.workshop-title h1 {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 38px;
    margin: 0;
}
.workshop-title p {
    margin: 5px 0 0;
}
.workshops {
    display: flex;
    flex-direction: column;
}
.workshop {
    display: flex;
}
.workshop:first-child {
    height: 700px;
}
.workshop:last-child {
    height: 550px;
}
.workshop:first-child .workshop-img, .workshop:last-child .workshop-description {
    min-width: 57%;
}
.workshop:first-child .workshop-img {
    background: no-repeat url("~@/assets/img/workshop-2.jpeg");
    background-size: cover;
    background-position: 5%;
}
.workshop:last-child .workshop-img, .workshop:first-child .workshop-description{
    min-width: 43%;
}
.workshop:last-child img {
    object-fit: cover;
    width: 150%;
}
.workshop:first-child p{
    width: 470px;
}
.workshop:last-child p{
    width: 485px;
}
.workshop-img {
    overflow: hidden;
}
.workshop-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.workshop p {
    margin: 25px;
}
.workshop-pos {
    display: flex;
    padding: 300px 100px 300px 300px;
    align-items: center;
    gap: 150px;
}
.workshop-pos div{
    flex-basis: 24%;
    gap: 30px;
}
.workshop-pos h2 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 35px;
}
.workshop-pos address {
    padding-bottom: 10px;
}
.workshop-pos span {
    line-height: 24px;
    font-size: 16px;
    font-style: initial;
}
.workshop-pos p {
    padding-bottom: 40px;
}
#google-map {
    flex-basis: 65%;
    height: 450px;
    border: none;
}

@media (max-width: 1150px) {
    .workshop-title {
        height: 280px;
    }
    .workshop-title h1 {
        font-size: 20px;
    }
    .workshop-title p {
        font-size: 11px;
    }
    .workshop {
        flex-direction: column;
        justify-content: space-between;
    }
    .workshop:first-child, .workshop:last-child {
        height: fit-content;
    }
    .workshop:first-child {
        flex-direction: column-reverse;
    }
    .workshop-img {
        width: 100%;
        height: 500px;
    }
    .workshop-description {
        padding: 50px 0;
    }
    .workshop:first-child p, .workshop:last-child p {
        width: 90%;
    }
    .workshop-pos, .workshop-pos div  {
        flex-direction: column;
        justify-content: center;
        align-items: center
    }
    .workshop-pos {
        gap: 50px;
        padding: 70px 0 30px;
    }
    .workshop-pos div {
        display: flex;
        gap: 14px;
    }    
    .workshop-pos h2, .workshop-pos address, .workshop-pos p {
        width: fit-content;
        margin: 0;
        padding: 0;
    }
    .workshop-pos h2 {
        font-size: 14px;
    }
    .workshop-pos span, .workshop-pos p {
        font-size: 11px;
        line-height: 17px;
        text-align: center;
    }
    .workshop-pos address {
        display: flex;
        flex-direction: column;
    }
    .workshop-pos p {
        width: 240px;
    }
    .workshop-pos button {
        margin-top: 7px;
        padding: 0;
    }
    #google-map {
        flex-basis: inherit;
        height: 417px;
        width: 369px;
    }
}
@media (max-width: 500px) {
    .workshop-img {
        max-height: 280px;
    }
    .workshop:first-child p, .workshop:last-child p {
        width: 278px;
        margin: 10px 0;
    }
}

</style>
