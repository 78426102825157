<template>
    <section id="entry-page">
        <div id="entry-gif">
        </div>
        <button ref="scrollTopButton" id="entry-button" class="button-white" @click="scrollToTop">
            <a>nous découvrir</a>
        </button>
        <img id="entry-img-1" src="../assets/img/Entry-1.png" alt="van réaménagé"  >
        <img id="entry-img-2" src="../assets/img/Entry-2.png" alt="nature" >
    </section>
    <div ref="home" class="container">
        <section class="home-bloc-1 bgc-hooker">
            <h1>participons à votre <span>aventure</span> ensemble</h1>
        </section>
        <section id="home-bloc-2">
            <div id="home-bloc-2-img"></div>
        </section>
        <section class="home-bloc-3 bgc-hooker">
            <img src="../assets/img/Artisanat-logo.png" alt="logo artisanat van">
            <h2>Artisanat Van, qu'est-ce que c'est ?</h2>
            <p>Nous sommes une entreprise spécialisée dans l’aménagement de véhicule dits de loisir, basée sur une méthode de fabrication à partir 
de matières recyclées : <strong>l’upcycling</strong>. Par ce biais, cela en fait une entreprise écologique et responsable. </p>
            <p>Ce qui nous tient principalement à coeur, c'est de créer une bulle de confort où le client s'y sent bien, s'y sent chez lui et en sécurité.	Nous nous efforçons de prendre en compte toutes ses idées, ses besoins 
(si il fait du ski, de la rando, du vélo,...) afin que l'aménagement soit prévu sur <strong>mesure</strong> par rapport à ses attentes.</p>
            <button class="button-white">
                <router-link to="/values">nos valeurs</router-link>
            </button>
        </section>
        <section class="home-bloc-4">
            <div class="conteneur-img-home">
                <img class="img1" src="../assets/img/layouts-1.jpeg" alt="table dans un van">
                <img class="img2" src="../assets/img/vehicle-4.jpeg" alt="cuisine dans un van">
                <img class="img3" src="../assets/img/home-gallery-1.jpeg" alt="lit dans un van">
                <img class="img4" src="../assets/img/gallery-1.jpeg" alt="lit dans un van">
                <img class="img5" src="../assets/img/gallery-5.jpeg" alt="van">
            </div>
            <div id="title">
                <h2>Des aménagements faits </h2>
                <div class="slider">
                    <div class="slide">
                        <span>localement</span>
                    </div>
                    <div class="slide">
                        <span>en recyclant</span>
                    </div>
                    <div class="slide">
                        <span>artisanalement</span>
                    </div>
                    <div class="slide">
                        <span>avec amour</span>
                    </div>
                    <div class="slide">
                        <span>localement</span>
                    </div>
                </div>
            </div>
            <p>Nous aimons concevoir des véhicules dans leur totale ou quasi-intégralité, cela nous permet de pouvoir structurer l’aménagement de notre client au plus près de ses attentes, tout en utilisant nos méthodes de fabrication.</p>
            <button class="button-black">
                <router-link to="/layouts">nos aménagements</router-link>
            </button>
        </section>
        <section id="home-bloc-5">
            <div id="home-bloc-5-img">
                <img src="../assets/img/jura-label.gif" alt="">
                <div>
                    <p>Découvrez le fonctionnement de notre atelier, situé au coeur du Jura </p>
                    <button class="button-white">
                        <router-link to="/workshop">atelier</router-link>
                    </button>
                </div>
            </div>            
        </section>
        <section class="home-bloc-6 bgc-brick-red">
            <div class="img-contact" >
            </div>
            <div class="links">
                <span>Retrouvez-nous</span>
                <a href="https://www.facebook.com/profile.php?id=100086455113900">
                    <img src="../assets/img/fb-white.png" alt="facebook">
                </a>                
                <a href="https://instagram.com/artisanatvan?igshid=ZDdkNTZiNTM=">
                    <img src="../assets/img/insta-white.png" alt="instagram">
                </a>                
                <a href="mailto:artisanatvan@gmail.com">
                    <img src="../assets/img/mail.png" alt="mail">
                </a>                
            </div>
            <div class="contact">
                <h2>On discute de votre projet ?</h2>
                <p>Venez discuter de vote futur aménagement afin de pouvoir définir ensemble vos besoins, vos envies, les différentes options et le tarif de votre projet.</p>
                <button class="button-white">
                    <router-link to="/contact">nous contacter</router-link>
                </button>
            </div>
        </section>
    </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll() {
            let scrollPosition = window.pageYOffset;
            const parallaxOne = document.getElementById('entry-img-1')
            const parallaxTwo = document.getElementById('home-bloc-2-img')

            parallaxOne.style.transform = `translateY(${(scrollPosition) * .5}px)`
            if (window.innerWidth > 1000) {
                parallaxTwo.style.transform = `translateY(${(scrollPosition-1900) * .2}px)`
            }
            
        },
        scrollToTop() {
            const home = this.$refs.home;
            const targetY = home.offsetTop;
            const step = targetY / 50;

            function scroll() {
                window.scrollBy(0, step);
                if (window.pageYOffset >= targetY) return;
                requestAnimationFrame(scroll);
            }
            scroll();
        }
    },
});
</script>

<style scoped>

#entry-page {
    height: 1000px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
#entry-img-1 {
    background: url(~@/assets/img/Entry-1.png);
    background-size: cover;
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
#entry-img-2 {
    background: url(~@/assets/img/Entry-2.png);
    background-size: cover;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
#entry-gif {
    background: no-repeat url(~@/assets/img/Artisanat-van.gif);
    background-size: contain;
    mix-blend-mode: screen;
    height: 630px;
    width: 900px;
    margin: 0 auto;
    z-index: 1;
}
#entry-button {
    padding: 10px 25px;
    border: 3px solid white;
    color: white;
    border-radius: 50px;
    background-color: transparent;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}
button a {
    color: white;
    font-size: 150%;
    text-transform: uppercase;
}
.home-bloc-1 {
    height: 850px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.home-bloc-1 h1 {
    text-transform: uppercase;
    text-align: center;
    width: 600px;
    font-size: 50px;
    font-weight: 300;
}

.home-bloc-1 h1:after {
    content: "";
    display: block;
    border-bottom: 1px solid white;
    padding-bottom: 30px;
    margin: 0 auto;
    width: 500px;
    
}
.home-bloc-1 span {
    text-transform: lowercase;
    font-family: Lora;
    font-style: italic;
}
#home-bloc-2 {
    height: 800px;
    overflow: hidden;
}
#home-bloc-2-img{
    background: no-repeat url(~@/assets/img/home-1.png);
    background-size: cover;
    height: 120%;
    width: 100%;
}
.home-bloc-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 200px 0;
    gap: 40px;
}
.home-bloc-3 img {
    width: 150px;
    padding-bottom: 50px;
    height: auto;
}
.home-bloc-3 h2 {
    font-size: 22px;
    margin: 15px;
    font-weight: bolder;
}
.home-bloc-3 p {
    margin: 0;
    width: 305px;
}
.home-bloc-3 button {
    width: 145px;
}
.home-bloc-3 button:hover a {
    top: 27%;
    left: 14%;
}
.home-bloc-4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 200px 0;
    overflow: hidden;
}
.conteneur-img-home {
    height: 650px;
    width: 111%;
    display: flex;
    gap: 20px; 
    padding-bottom: 150px;
}
.conteneur-img-home img {
    object-fit: cover;
}
.conteneur-img-home img:hover {
    padding: 35px;
    transform: scale(115%, 115%);
    transition: all 1000ms;
}
.img1 {
    width: 480px;
    height: 439px;
    align-self: center;
}
.img2 {
    width: 520px;
    height: 458px;
    align-self: flex-end;
}
.img3 {
    width: 430px;
    height: 553px;
    align-self: flex-start;
}
.img4 {
    width: 510px;
    height: 496px;
    align-self: flex-start;
}
.img5 {
    width: 295px;
    height: 337px;
    align-self: center;
}
.home-bloc-4 #title {
    position: relative;
    overflow: hidden;
    width: 695px;
    display: flex;
    align-items: center;
}
.home-bloc-4 h2, .home-bloc-4 span {
    font-family: lora;
    font-style: italic;
    font-weight: 500;
    font-size: 38px;
    text-align: left;
}
@keyframes scroll {
	0%, 12.5% { transform: translateY(0); }
    25%, 37.5%{ transform: translateY(-100px); }
    50%, 62.5%{ transform: translateY(-200px); }
    75%, 87.5%{ transform: translateY(-300px); }
    100%{ transform: translateY(-400px); }
    
}
.slider {
    animation: scroll 8s linear infinite ;
    position: absolute;
    left: 430px;
    top: 30px;
}
.slide {
    height: 100px;
    text-align: left;
}
.slider::before,
.slider::after {
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
    transform: rotateY(180deg);
}
.home-bloc-4 p {
    width: 480px;
    padding-bottom: 30px;
}
.button-black a {
    color: black;
    font-size: 150%;
    text-transform: uppercase;
}
.button-black {
    border: 3px solid black;
    color: black;
    border-radius: 50px;
    background-color: transparent;
    position: relative;
    overflow: hidden;
    width: 250px;
    height: 30px;
}
.button-black::before {
    content: "";
    display: block;
    width: 300px;
    height: 50px;
    background-color: black;
    position: absolute;
    top: 0;
    left: -300px;
    z-index: 0;
    transition: all 500ms ease-in;
}
.button-black:hover a{
    position: absolute;
    color: white;
    top: 27%;
    left: 13.5%;
    transition: all 500ms ease-in-out;
}
.button-black:hover::before{
    left: 0;
    transition: all 500ms ease-in-out;
}
#home-bloc-5 {
    height: 950px;
    overflow: hidden;
}
#home-bloc-5-img{
    background: no-repeat url(~@/assets/img/home-2.jpeg);
    background-size: cover;
    background-position: center;
    position: relative;
    height: 120%;
    width: 100%;
}
#home-bloc-5-img img {
    mix-blend-mode: screen;
    width: 400px;
    position: absolute;
    right: 15%;
    height: auto;
}
#home-bloc-5-img div {
    text-align: center;
    color: white;
    width: 300px;
    position: absolute;
    top: 50%;
    right: 10%;
} 
#home-bloc-5-img p {
    padding-bottom: 25px;
}
#home-bloc-5-img button {
    width: 100px;
}
#home-bloc-5-img button:hover a {
    top: 27%;
    left: 23%;
}
.home-bloc-6 {
    padding: 150px 75px 150px;
    display: flex;
    align-items: center;
}
.img-contact {
    background: no-repeat url(~@/assets/img/home-3.jpeg);
    background-size: cover;
    background-position: center;
    width: 650px;
    height: 450px;
}
.links {
    padding: 90px 5%;
    border-right: 1px white solid;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    min-width: 200px;
    max-width: 210px;
    min-height: 100px;
}
.links span {
    padding-bottom: 15px;
    font-size: 22px;
}
.links img{
    height: 40px;
    width: 40px;
    fill: white;
}
.links img:hover {
    width: 50px;
    height: 50px;
    transition: all 500ms ease;
}
.contact {
    flex-basis: 40%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 30px;
    gap: 20px;
}
.contact h2 {
    font-size: 22px;
    margin: 0;
    font-weight: bolder;
}
.contact p {
    margin-top: 0;
    width: 440px;
}
@media (min-width: 2000px) {
    #entry-img-1, #entry-img-2 {
        background-size: 100%;
    }
    .conteneur-img-home {
        width: 124%;
    }
}
@media (min-width: 650px) and (max-width: 1250px) { 
    .home-bloc-6 {
        flex-direction: column;
    }
    .links {
        border: none;
    }
}
@media (min-width: 450px) and (max-width: 900px) {
    #entry-gif {
        width: -webkit-fill-available;
    }
}
@media (max-width: 650px) {
    .home-bloc-6 {
        padding: 100px 15px 70px;
        justify-content: space-between;
    }
    .links {
        padding: 0;
        width: 150px;
        min-width: 0;
        border-right: 0;
    }
    .links span, .contact h2 {
        font-size: 16px;
    }
    .links img {
        height: 30px;
        width: 30px;
    }
    .contact {
        border-left: 1px white solid;
        padding-left: 15px;
        flex-basis: content;
    }
    .contact p {
        width: 217px;
    }
    .contact button {
        padding: 0px 10px;
    }
    .img-contact {
        display: none;
    }
}
@media (max-width: 555px) {
    .home-bloc-1 h1, .home-bloc-1 h1:after {
        width: 100%;
    }
    .home-bloc-4 #title {
        width: 340px;
        font-size: 18px;
    }
    .home-bloc-4 h2, .home-bloc-4 span{
        font-size: 18px;
        font-weight: bolder;
    }
    .slider {
        left: 210px;
        top: 13px;
    }
    .home-bloc-4 {
        padding: 70px 0 90px;
    }
    .conteneur-img-home {
        height: 230px;
        width: 90%;
        gap: 5px;
        padding-bottom: 50px;
    }
    .img1 {
        width: 165px;
        height: 165px;
        order: -2;
    }
    .img5 {
        width: 182px;
        height: 173px;
        order: -1;
        align-self: flex-end;
    }
    .img3 {
        width: 151px;
        height: 210px;
    }
    .img4, .img2 {
        display: none;
    }
    .home-bloc-4 p {
        width: 322px;
    }
    .button-black {
        width: 178px;
        height: 20px;
    }
    .button-black a {
        font-size: 16px;
    }
}
@media (max-width: 450px) {
    #entry-page {
        background: no-repeat url(~@/assets/img/gallery-4.jpeg);
        background-size: 280%;
        background-position: 45% 0;
        background-color: #1C2C2B;
        justify-content: flex-end;
        height: 820px;
    }
    #entry-page img {
        display: none
    }
    #entry-gif {
        width: 100%;
        mix-blend-mode: color-dodge;
        height: 300px;
        position: absolute;
        bottom: -5%;
    }
    #entry-button {
        padding: 2px 10px;
        background-color: #fff;
    }
    #entry-button a {
        color: black;
    }
    .home-bloc-1 {
        height: 400px;
    }
    .home-bloc-1 h1 {
        font-size: 28px;
        width: 90%;
    }
    .home-bloc-1 h1::after {
        width: 70%;
    }
    #home-bloc-2 {
        height: 300px;
    }
    .home-bloc-3 {
        padding: 100px 8%;
        gap: 25px;
    }
    .home-bloc-3 img {
        width: 75px;
        padding: 0;
    }
    .home-bloc-3 h2 {
        margin: 0 0 10px 0;
        font-size: 16px;
    }
    .home-bloc-3 button {
        width: 130px;
    }
    button a {
        font-size: 16px;
        font-weight: 500;
    }
    .conteneur-img-home {
        width: 123%;
    }
    #home-bloc-5 {
        height: 500px;
    }
    #home-bloc-5-img {
        height: 100%;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
    }
    #home-bloc-5-img img {
        width: 230px;
        right: -5%;
        top: 8%;
    }
    #home-bloc-5-img div {
        position: relative;
        top: 0;
        right: 0;
        padding-bottom: 70px;
    }
    #home-bloc-5-img p {
        width: 215px;
        margin: 0 auto;
    }
    #home-bloc-5-img button {
        width: 86px;
        padding: 0px 5px;
    }
}

</style>
