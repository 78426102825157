<template>
    <section class="values-intro">
        <h1>nos valeurs</h1>
    </section>
    <section class="values bgc-hooker">
        <div class="value">
            <div class="value-img">
                <img src="../assets/img/values-1.jpeg" alt="forêt">
            </div>            
            <div class="value-text">
                <h2>
                    <img src="../assets/img/nature.png" alt="brin herbe">
                    L’amour de la nature</h2>
                <p>Artisanat Van est situé dans le Jura, département œuvrant pour une politique de protection, de gestion et d’ouverture au public des espaces naturels sensibles, destinée à préserver la qualité des sites, des paysages et d’assurer la sauvegarde des habitats naturels.</p>
                <p>Nous avons la chance d’avoir une grande diversité d’essences d’arbres disponible ainsi que des forêts bien entretenues dans notre région. Cela nous permet d'avoir une grande diversité de choix de bois local. Étant nous même très proches de la nature, le lieux d'implantation était important, le Jura répond parfaitement à nos valeurs</p>
            </div>
        </div>
        <div class="value">
            <div class="value-text">
                <h2>
                    <img src="../assets/img/sharing.png" alt="poigné de main">
                    Le partage
                </h2>
                <p>Nous recherchons d'abord une certaine complicité avec l'intéressé pour comprendre au mieux sa façon de concevoir le voyage, sa manière de vivre, etc... Comme si nous allions participer à l'aventure ensemble. Ce que nous souhaitons avant tout c'est de permettre au client de pouvoir vivre la "vanlife" en toute sérénité.</p>
            </div>
            <div class="value-img">
                <img src="../assets/img/values-2.jpeg" alt="réunion">
            </div>            
        </div>
        <div class="value">
            <div class="value-img">
                <img src="../assets/img/values-3.jpeg" alt="van">
            </div>
            <div class="value-text">
                <h2>
                    <img src="../assets/img/vehicle-font.png" alt="logo van">
                    Le voyage
                </h2>
                <p>Nous possédons nos propres vans dont nous avons imaginé la conception par rapport à nos activités quotidiennes (pour pratiquer des saisons, partir en voyage, partir en week-end).</p>
                <p>Ce qui pour nous est primordial, c’est de pouvoir se retrouver au plus près de la nature et de l’écologie lors des voyages. Pouvoir aller où l’on veut, s’arrêter quand on le souhaite, se couper un peu du monde, se sentir libre ! C’est la possibilité de découvrir de nouvelles cultures mais aussi de partager la nôtre. Pouvoir accéder à des lieux sublimes, que l’on rêve de voir de nos propres yeux. C’est également avoir la possibilité d’aller au plus près de l’habitant, partager un petit peu de son quotidien, l’aider dans ses tâches si l’envie nous en prend.</p>
                <p>Pratiquant les sports de saison, le van nous a ouvert les possibilités de nous déplacer à notre guise en emmenant tout le matériel nécessaire, avec en bonus, tout le confort dont nous avons besoin. La pratique du voyage nous permet de connaître les besoins, les avantages et les inconvénients de ce mode de vie et donc d’apporter les changements nécessaires afin de les diminuer.</p>
            </div>
        </div>
    </section>
</template>

<style scoped>
.values-intro {
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
}
h1{
    text-transform: uppercase;
    font-size: 38px;
    font-weight: 300;
    text-align: center;
    padding-top: 100px;
}
.values {
    display: flex;
    flex-direction: column;
}
.value {
    flex-basis: 33%;
    display: flex;
}
.value-img {
    min-width: 50%;
    max-width: 50%;
    overflow: hidden
}
.value-img:nth-child(2-n) {
    height: 635px;
}
.value-img:last-child {
    object-fit: cover;
}
.value img {
    width: 100%;
    height: 100%;
}
.value img:hover {
    transform: scale(115%, 115%);
    transition: all 500ms;
}
.value-text {
    padding: 4% 11%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.value-text {
    padding: 6% 11%;
}
.value-text h2 {
    display: flex;
    align-items: center;
    gap: 11px;
}
.value:nth-child(1) h2 img {
    width: 27px;
    height: 30px;
}
.value:nth-child(2) h2 img {
    width: 35px;
    height: 24px;
}
.value:nth-child(3) h2 img {
    width: 38px;
    height: 42px;
}
.value-text h2, .value-text p {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 25px;
}
.value-text p {
    margin: 10px 0;
}
@media (max-width: 900px) {
    .values-intro {
        height: 400px;
    }
    h1 {
        font-size: 20px;
    }
    .value {
        flex-direction: column;
    }
    .value:nth-child(2) {
        flex-direction: column-reverse;
    }
    .value-img {
        max-width: 100%;
        height: 315px;
    }
    .value-text {
        padding: 50px 75px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .value-text h2::before {
        padding: 14px;
        border: 1px solid white;
    }
    .value-text h2, .value-text p {
        font-size: 11px;
    }
}
</style>
